<template>
	<div class="page-container vh-100 overflow-auto">
		<div class="page-header-wrapper">
			<label class="header-title">Trip Tracking</label>
			<div class="input-group input-group-flush d-flex flex-row border" v-if="activeTrips && activeTrips.length">
				<span class="input-group-text border-0">
					<i class="fe fe-search"></i>
				</span>
				<input
					@input="openActiveTripsPanel(true)"
					v-model.trim="searchQuery"
					class="form-control h-100 list-search"
					type="search"
					placeholder="Search active trip"
					/>
			</div>
		</div>

		<div class="d-flex content-wrapper">

			<div v-if="isLoading" class="d-flex justify-content-center align-items-center w-100">
				<div class="spinner-border" role="status"></div>
			</div>

			<template v-else>
				<template v-if="activeTrips && activeTrips.length">
					<active-trips-map-widget
						v-show="tripsPanelOpen"
						:active-trips="activeTrips"
						:total-trips="totalTrips"
						:loading-trips="loadingMoreTrips"
						:search-query="searchQuery"
						@load-more-trips="loadMore"
						@close="closeActiveTripsPanel()"
						@trip-selected="selectActiveTrip"
						@initialized="registerListeners"
						>
					</active-trips-map-widget>
					<div class="col-12 px-0 position-relative">
						<div class="view-trips" @click="openActiveTripsPanel()">
							<svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2.4793 1.07185C1.71571 0.561743 0.603308 1.11069 0.685998 2.10355L0.710115 2.39313C1.07806 6.81111 1.85626 11.1879 3.03523 15.4705C3.31601 16.4905 4.72717 16.5783 5.15558 15.633L6.92943 11.7188C7.09901 11.3447 7.51077 11.1045 7.9576 11.1523L12.3462 11.6223C13.3536 11.7302 14.0655 10.5002 13.2582 9.72086C10.0175 6.59233 6.49265 3.75289 2.72631 1.23686L2.4793 1.07185Z" fill="white"/>
							</svg>
							<span class="ml-2">Click to view active trips</span>
						</div>
						<div
							id="google-map-area"
							class="h-100"
							style="width: 100%"
							></div>
					</div>
				</template>
				<div class="d-flex justify-content-center align-items-center w-100" v-else>
					<div>
						<img src="@/assets/img/bus_placeholder.svg">
						<p class="text-center">There are currently no active trips</p>
					</div>
				</div>
			</template>

		</div>
		<trip-details-modal
			:show="isPassengersModalShown"
			:selected-marker-trip-data="selectedMarkerTripData"
			@close="isPassengersModalShown = false"
			></trip-details-modal>
	</div>
</template>

<script>
import { io } from 'socket.io-client'
import { Loader } from '@googlemaps/js-api-loader'
import moment from 'moment'
import TripDetailsModal from '@/components/modals/BusMarkerDetailsModal.vue'
import ActiveTripsMapWidget from '@/components/modules/commute/trips/ActiveTripsMapWidget'
import { busMarker } from '@/assets/svg/inline-svg'

const gMapLoader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_APIKEY,
  version: 'weekly',
  libraries: ['geometry']
})

export default {
  components: {
    ActiveTripsMapWidget,
    TripDetailsModal
  },
  filters: {
    dateformat (v) {
      return moment(v).format('YYYY-MM-DD HH:mm')
    }
  },
  data () {
    return {
      activeTrips: [],
      activeTrip: null,
      mapInstance: null,
      uniqueTripData: {},
      uniqueTripTracking: {},
      searchQuery: '',
      activeTripsMarkers: {},
      isHovered: false,
      isLoading: false,
      isPassengersModalShown: false,
      selectedMarkerTripData: null,
      tripsPanelOpen: false,
      totalTrips: 0,
      loadingMoreTrips: false,
      pageSize: 20,
      markerClickListener: () => {}
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters.currentUser
    },
    token () {
      if (!this.currentUser) return null
      return this.currentUser.token.token
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.fetchActiveTrips()
    })
  },
  beforeUnmount () {
    this.removeTripListeners()
  },
  methods: {
    registerListeners (tripPanelOptions) {
      this.markerClickListener = tripPanelOptions.highlightListener
    },
    openActiveTripsPanel (searchContext) {
      if (searchContext) {
        if (this.searchQuery) {
          this.tripsPanelOpen = true
        }
      } else {
        this.tripsPanelOpen = true
      }
    },
    closeActiveTripsPanel () {
      this.tripsPanelOpen = false
    },
    async loadActiveTrips (page = 1) {
      const res = await this.axios.get(`v1/trips/active?limit=${this.pageSize}&page=${page}&metadata=true`)
      return res.data
    },
    paintTripMarkers () {
      this.initWebSocket(this.activeTrips)
    },
    fetchActiveTrips () {
      this.isLoading = true
      this.loadActiveTrips()
        .then((data) => {
          this.activeTrips = data.data
          this.totalTrips = data.metadata?.total || 0
          this.isLoading = false
        })
        .finally(() => {
          this.isLoading = false
          if (this.activeTrips.length) {
            this.loadGoogleMapsLib()
          }
        })
    },
    async loadGoogleMapsLib () {
      const vm = this
      gMapLoader
        .load()
        .then(() => {
          vm.mapInstance = new window.google.maps.Map(
            document.getElementById('google-map-area'),
            {
              center: { lat: 6.5244, lng: 3.3792 },
              zoom: 12,
              streetViewControl: false,
              mapId: process.env.VUE_APP_MAP_ID,
              fullscreenControl: false,
              disableDefaultUI: true,
              zoomControl: true
            }
          )
        })
        .finally(() => {
          vm.paintTripMarkers()
        })
    },
    updateTripInformation (tripInfo, locationInfo) {
      const prevTrackingInfo = this.uniqueTripTracking[tripInfo.id]
      const { position_latitude, position_longitude } = locationInfo

      if (!this.activeTripsMarkers[tripInfo.id]) {
        this.setTripCurrentMarker(
          tripInfo,
          position_latitude,
          position_longitude
        )
      } else {
        const newLatLng = new window.google.maps.LatLng(
          position_latitude,
          position_longitude
        )
        this.activeTripsMarkers[tripInfo.id].marker.setPosition(newLatLng)
        this.activeTripsMarkers[tripInfo.id].tMarker.setPosition(newLatLng)
      }

      if (
        prevTrackingInfo &&
        this.coordinateChanged(prevTrackingInfo, locationInfo)
      ) {
        const icon = this.activeTripsMarkers[tripInfo.id].marker.getIcon()
        const rotation = this.computeHeading(prevTrackingInfo.position_latitude,
          prevTrackingInfo.position_longitude,
          position_latitude,
          position_longitude)
        icon.url = busMarker(rotation)
        this.activeTripsMarkers[tripInfo.id].marker.setIcon(icon)
      }
      this.uniqueTripTracking[tripInfo.id] = locationInfo
    },
    coordinateChanged (previousCoordinateData, currentCoordinateData) {
      return (
        previousCoordinateData.position_latitude !==
          currentCoordinateData.position_latitude ||
        previousCoordinateData.position_longitude !==
          currentCoordinateData.position_longitude
      )
    },
    setTripCurrentMarker (tripInfo, newLatitude = null, newLongitude = null) {
      const startingPoint = tripInfo.route.pickup_coordinate
        .split(',')
        .map((i) => parseFloat(i.trim()))
      const [lat, lng] = startingPoint
      if ((lat && lng) || (newLatitude && newLongitude)) {
        this.activeTripsMarkers[tripInfo.id] = this.addBusMarker(
          lat || 0,
          lng || 0,
          newLatitude || lat,
          newLongitude || lng,
          `${tripInfo.route.route_code}`,
            `B/S: ${tripInfo.route.pickup}`,
          `Destination: ${tripInfo.route.destination}`,
          tripInfo.id
        )
      }
    },

    initWebSocket (activeTrips) {
      const socket = io(process.env.VUE_APP_WS_BASE_URL, {
        transports: ['websocket', 'polling'],
        path: '/telemetry/socket.io',
        auth: { token: this.token },
        query: { token: this.token }
      })
      socket.on('connect_error', (err) => {
        
      })
      socket.on('connect', () => {
        activeTrips.forEach((trip) => {
          this.initializeTripListener(trip)
        })
      })
      this.io = socket
      window.io = socket
    },
    removeTripListeners () {
      this.io.disconnect()
    },
    initializeTripListener (trip) {
      if (!this.uniqueTripData[trip.id]) {
        this.uniqueTripData[trip.id] = trip
        // this.setTripCurrentMarker(trip);
        window.io.on(`trips:${trip.id}`, (data) => {
          if (data) {
            this.updateTripInformation(trip, data)
          }
        })
      }
    },
    closePassengersModal () {
      this.isPassengersModalShown = false
      this.selectedUserRoutes = []
    },
    showPassengersModal (tripId) {
      this.selectedUserRoutes = this.activeTrips.filter(
        (trip) => trip.id == tripId
      )
      this.selectedMarkerTripData = this.selectedUserRoutes.length
        ? this.selectedUserRoutes[0]
        : null
      this.isPassengersModalShown = true
    },

    addBusMarker (prevLat, prevLng, lat, lng, title, startingPoint, description, tripId) {
      const latLng = new window.google.maps.LatLng(lat, lng)
      const bearing = this.computeHeading(prevLat, prevLng, lat, lng)
      const marker = new window.google.maps.Marker({
        position: latLng,
        map: this.mapInstance,
        collisionBehavior: 'REQUIRED_AND_HIDES_OPTIONAL',
        icon: {
          // labelOrigin: {x: 8, y: 35}, //below marker
          url: busMarker(bearing),
          anchor: new window.google.maps.Point(33, 45),
          size: new window.google.maps.Size(66, 98),
          rotation: bearing
        }
      })

      // Using this to handle events due to asset dimension used in rendered marker
      const tMarker = new window.google.maps.Marker({
        position: latLng,
        map: this.mapInstance,
        optimized: false,
        visible: true,
        label: {
          text: title,
          className: 'marker-label'
        },
        collisionBehavior: 'REQUIRED_AND_HIDES_OPTIONAL',
        icon: {
          labelOrigin: { x: 14, y: -14 }, // above marker
          url: 'https://maps.gstatic.com/mapfiles/transparent.png',
          size: new window.google.maps.Size(20, 46),
          anchor: new window.google.maps.Point(10, 23)
        }
      })

      let infoWindow = null
      const infoWindowOffSet = new window.google.maps.Size(0, 0)
      tMarker.addListener('click', () => {
        this.openInfoWindow(tMarker, title, startingPoint, description, infoWindowOffSet)
        this.panAndZoom(marker.getPosition())
        this.highlightTripOnSidePanel(tripId)
      })
      tMarker.addListener('mouseover', () => {
        infoWindow = this.openInfoWindow(tMarker, title, startingPoint, description, infoWindowOffSet)
      })
      tMarker.addListener('mouseout', () => {
        if (infoWindow) {
          infoWindow.close()
        }
      })

      return { marker, tMarker }
    },
    rotateMarkerParent (label, degree) {
      const el = document.body.querySelector(`div[aria-label$="${label}"]`)
      if (el) {
        el.style.transform = `rotate(${degree}deg)`
        el.style.transformOrigin = 'center'
      }
    },
    openInfoWindow (busMarker, title, start, destination, pixelOffset = null) {
      const img = require('@/assets/img/mini_marker_dark.svg')
      const contentString =
          '<div class="info-window">' +
          `<div class="info-window__source"><p class="d-flex mb-3"><span class="source-badge"></span> <span class="text">${start}</span></p></div>` +
          `<div class="info-window__destination"><p class="d-flex align-items-start mb-0"><img src="${img}" class="icon" /> <span class="text"> ${destination}</span></p></div>` +
          '</div>'
      const extras = pixelOffset ? { pixelOffset } : {}
      const infoWindow = new window.google.maps.InfoWindow({
        content: contentString,
        ...extras
      })
      infoWindow.open(this.mapInstance, busMarker)
      return infoWindow
    },
    computeHeading (lat1, lng1, lat2, lng2) {
      const point1 = new window.google.maps.LatLng(lat1, lng1)
      const point2 = new window.google.maps.LatLng(lat2, lng2)
      return window.google.maps.geometry.spherical.computeHeading(
        point1,
        point2
      )
    },
    selectActiveTrip (trip) {
      this.activeTrip = trip
      this.panToSelectedActiveTrip(trip)
    },
    panToSelectedActiveTrip (trip) {
      const tripToPanTo = this.activeTripsMarkers[trip.id]?.marker
      if (tripToPanTo) {
        this.panAndZoom(tripToPanTo.getPosition())
      }
    },
    panAndZoom (position) {
      this.mapInstance.panTo(position)
      this.mapInstance.setZoom(15)
    },
    loadMore (page) {
      this.loadingMoreTrips = true
      this.loadActiveTrips(page)
        .then((data) => {
          const newTrips = (data?.data || [])
          this.activeTrips.push(...newTrips)
          newTrips.forEach((trip) => {
            this.initializeTripListener(trip)
          })
          this.totalTrips = data?.total || 0
          this.isLoading = false
        })
        .finally(() => {
          this.loadingMoreTrips = false
        })
    },
    highlightTripOnSidePanel (tripId) {
      this.openActiveTripsPanel()
      this.markerClickListener.call(this, tripId)
    }
  }
}
</script>
<style lang="scss" scoped>
$neutral-400: #ACAFAE;
$neutral-800: #313533;
$neutral-600: #737876;
.small {
  font-size: 0.7rem;
  max-width: 7rem;
}

.page-container {
  background: #F5F5F5;
  padding: 1.8rem;

  .page-header-wrapper {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .header-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      /* Gray/Gray3 */
      color: #444854;
    }

    .input-group {
      background: #FFFFFF;
      border: 0.5px solid #C0C0C0;
      border-radius: 8px;
      width: 550px;
      max-width: 100%;
    }
  }

  .content-wrapper {
    border: 3px solid #fff;
    position: relative;
    height: calc(100vh - 8rem);

    & .view-trips {
      background: $neutral-800;
      border-radius: .5rem;
      position: absolute;
      left: 1rem;
      top: 1rem;
      color: #FFFFFF;
      padding: .5rem 1.5rem;
      cursor: pointer;
      z-index: 1;
    }

    & .trips-card {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(16, 18, 17, 0.4);
      display: flex;
    }
  }

}
</style>
<style lang="scss" scoped>
.marker-label {
  font-size: 10px !important;
  background: #fff;
  padding: 3px 10px;
  border-radius: 5px;
  font-weight: 500;
}
.gm-style .gm-style-iw-c {
  border-radius: 1rem;
  box-shadow: none;

  & .gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
  }
}
.info-window {
  padding: 0 0.25rem;
  background: #FFFFFF;
  font-weight: 400;
  font-size: 12px;
  max-width: 20rem;

  .text {
    max-width: 10rem;
  }

  &__source {
    color: #313533;
    position: relative;
    &:before {
      border-left: 1px dashed #a3a3a3;
      position: absolute;
      top: 12px;
      left: 4px;
      height: 80%;
      content: "";
    }

    .source-badge {
      background: #20E682;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 1rem;
    }
  }

  &__destination {
    color: #ACAFAE;
    .icon {
      margin-right: 1rem;
      width: 10px;
    }
  }
}
</style>
